<template>
<div class="mt-2">
    <button v-b-modal.modal-multi-1  class="btn btn-xs btn-primary" @click.prevent>Extra Field</button>
    <b-modal id="modal-multi-1" size="sm" centered title="Add Extra Fields" ok-only>
        <div class="col-md-12 pd-r-7-5">
            <div class="row">
                <div class="col-md-12">
                <div class="group">
                    <label class="group-label">Extra Fields</label>
                    <div class="group-attribute-container" v-if="extraFields">
                        <div class="row mb-2" v-for="(field,index) in extraFields" :key="index">
                            <div class="col-2">
                                <input type="checkbox" 
                                :id="field.slug"
                                :value="field.slug"
                                @change="addToFormData()" 
                                v-model="selectedNewExtra">
                            </div>
                            <div class="col-10">
                                <label for="extra1" class="font-l ml-2 mb-0 pb-0 line-ht">{{field.title}} - 
                                    {{ field.type == 0 ? 'Addition' : 'Subtraction'}} -  {{ field.type == 0 ? 'Addition' : 'Subtraction'}}
                                    {{ field.vat == 0 ? 'Non Vatable': 'Vatable'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button v-b-modal.modal-multi-2 class="btn btn-success btn-xs mt-2" @click.prevent>Add New Field</button>
                </div>
                </div>
            </div>
        </div>
    </b-modal>
</div>      
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed:{
        ...mapGetters('purchase',[
           'extraFields',
           'selectedNewExtra'
         ]),
        //  selectedNewExtra:{
        //      set(value){
        //          console.log(value);
        //         //  this.$store.commit('purchase/setSelectedExtra',value);
        //         //  return value;
        //      }
        //  }
       
    },
    mounted(){
    },
    methods:{
        addToFormData(){
            this.$store.commit('purchase/setSelectedExtra',this.selectedNewExtra);
        }
    }
}
</script>