<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form
      class="row purchase-ht4 no-print"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <div class="col-12 pb-1">
        <div class="row">
          <div class="col-10 pl-0">
            <h4 class="content-title content-title-xs">Edit Purchase</h4>
          </div>
          <div class="col-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              :disabled="branchDisabled"
              @change="getDataByBranch"
            >
              <option :value="formData.branch" selected>
                {{ formData.branchName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-3 bg-theam-secondary mx-0 p-2">
        <div
          :class="
            dataSets.purchase_setting.shipping_details == 1
              ? 'purchase-supplier-ht'
              : 'purchase-supplier-ht-change'
          "
        >
          <div>
            <span class="title-supplier">Supplier</span>
            <!-- <button
                class="float-right btn btn-primary btn-xs"
                type="button"
                @click="
                $store.dispatch('modalWithMode', {
                  id: 'newSupplier',
                  mode: 'create',
                })
              "
            >
              New Supplier
            </button>-->
          </div>
          <div class="input-group py-2">
            <button
              id="supplier_name"
              class="btn-primary input-group-text"
              type="button"
            >
              <i class="fa fa-user"></i>
            </button>
            <input
              type="text"
              class="form-control"
              placeholder="Supplier Name"
              v-model="formData.supplierName"
              readonly
              disabled
            />
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="date">Purchase Date *</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                v-model.trim="formData.date"
                class="form-control"
                required
                type="date"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="purchase_id">Purchase ID</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                v-model="purchaseIdWithPrefix"
                class="form-control"
                type="text"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="chalan_no">Chalan No.</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                id="chalan_no"
                v-model.trim="formData.chalan_no"
                class="form-control"
                name="chalan_no"
                placeholder="Supplier Bill No."
                type="text"
              />
            </div>
          </div>
          <div class="input-group py-2 zi-0">
            <input
              id="file"
              name="file"
              style="display: none"
              type="file"
              @change="base64Image"
            />
            <input
              class="form-control"
              placeholder="Upload PDF / Image"
              readonly
              style="background: #f4f7fa"
              type="text"
              v-model="formData.image"
            />
            <label class="btn-primary input-group-text cursor-p" for="file"
              >Browse</label
            >
          </div>
        </div>
        <div class>
          <div class="row" v-if="dataSets.purchase_setting">
            <div class="col-7 mr-0 pr-0">
              <button
                class="btn btn-xs btn-primary"
                type="button"
                @click="$store.dispatch('modalOpen', 'shippingDetils')"
                v-if="dataSets.purchase_setting.shipping_details == 1"
              >
                Shipping Detail
              </button>
            </div>
            <div class="col-5 ml-0 pt-0 d-flex justify-content-end">
              <div
                class="from-group-check"
                v-if="dataSets.purchase_setting.export == 1"
              >
                <input
                  id="expense_import"
                  v-model="formData.shipping_type"
                  class="group-check-box purchase-import-check"
                  name="expense_type"
                  type="checkbox"
                  value="0"
                />
                <label
                  class="page-text-import pd-t-10 ml-2 mb-0"
                  for="expense_import"
                  >Import</label
                >
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="payment_method"
                >Payment Method</label
              >
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                class="form-control"
                v-model="formData.payment_method"
                @change="selectPaymentMethod"
              >
                <option value selected disabled>Select Method</option>
                <option
                  v-for="(methods, index) in dataSets.paymentMethods"
                  :key="index"
                  :value="methods.id"
                >
                  {{ methods.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label class="page-text">Bank</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <select
                v-model="formData.bank"
                v-if="formData.paymentTitle.toLowerCase() == 'bank'"
                class="form-control"
                @change="selectBank()"
              >
                <option value v-if="dataSets.banks.length == 0">
                  No Banks found
                </option>
                <option
                  v-else
                  v-for="(bank, index) in dataSets.banks"
                  :key="index"
                  :value="bank.id"
                >
                  {{ bank.bank }}
                </option>
              </select>
              <input class="form-control" type="text" v-else disabled />
            </div>
          </div>
          <div class="row">
            <!-- Ref. is replaced with txn. id -->
            <div class="col-5 mr-0 pr-0">
              <label class="page-text" for="txn_id">Ref.</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                id="txn_id"
                v-model.trim="formData.txn_id"
                class="form-control"
                placeholder="Payment Ref."
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-6 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    id="product"
                    class="btn-primary input-group-text"
                    type="button"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Item"
                  v-model="items.name"
                  data-toggle="modal"
                  data-target="#purchaseItemList"
                  @click="openPurchaseitemComponent()"
                  readonly
                />
                <button
                  class="float-left btn btn-primary btn-xs ml-2 pr-0 mt-0 mr-2"
                  type="button"
                  @click="
                    $store.dispatch('modalWithMode', {
                      id: 'newItem',
                      mode: 'create',
                    })
                  "
                >
                  New Item
                </button>
              </div>
            </div>
            <!-- <div class="col-1 pl-2 m-0 order-add-item-btn-pd">
              <button
                  class="float-left btn btn-primary btn-xs px-0"
                  type="button"
                  @click="
                  $store.dispatch('modalWithMode', {
                    id: 'newItem',
                    mode: 'create',
                  })
                "
              >
                New
              </button>
            </div>-->
            <div
              class="col-4 mr-0 ml-0 pl-1 pr-3"
              v-if="dataSets.item_setting.barcode == 1"
            >
              <div class="page-text">Barcode</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span id="barcode" class="input-group-text">
                    <i class="fas fa-barcode"></i>
                  </span>
                </div>
                <input
                  v-model.trim="items.barcode"
                  class="form-control"
                  placeholder="Barcode"
                  type="text"
                />
              </div>
            </div>
            <div
              class="col-2 ml-0 pl-0 mr-0 pr-3"
              v-if="dataSets.item_setting.expiry == 1"
            >
              <div class="page-text">Expiry Date</div>
              <div class="input-group">
                <input
                  v-model="items.expiry_date"
                  class="form-control"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Quantity *</div>
              <div class="input-group">
                <input
                  v-model.number="items.quantity"
                  class="form-control"
                  min="1"
                  placeholder="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span
                    id="quantity"
                    class="input-group-text"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-2 mr-0 pr-0 pt-2">
              <div class="page-text">Purchase Rate *</div>
              <div class="input-group">
                <input
                  v-model="items.purchase_rate"
                  class="form-control"
                  placeholder="Amount"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
              </div>
            </div>
            <div class="col-2 pt-2 pr-0 pl-1">
              <div class="page-text">Purchase Discount</div>
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="items.purchase_discount_percentage"
                  placeholder="0.00"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  class="form-control wd-20pimp"
                  v-model="items.purchase_discount_amount"
                  placeholder="0.00"
                  step="any"
                  type="number"
                  min="0"
                  @keyup="changePurchaseRate()"
                  @change="changePurchaseRate()"
                />
              </div>
            </div>
            <div class="col-2 mr-0 pr-0 pt-2">
              <div class="page-text">A. Purchase Rate *</div>
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="items.actual_purchase_rate"
                  placeholder="Amount"
                  step="any"
                  type="number"
                  disabled
                />
              </div>
            </div>
            <div class="col-2 mr-0 pt-2">
              <div class="page-text">Sales Rate *</div>
              <div class="input-group">
                <input
                  v-model="items.sales_rate"
                  class="form-control"
                  placeholder="Amount"
                  min="0"
                  step="any"
                  type="number"
                  @keyup="discountAmount()"
                  @change="discountAmount()"
                />
              </div>
            </div>
            <div class="col-2 pt-2 pl-0">
              <div class="page-text">Sales Discount</div>
              <div class="input-group">
                <input
                  v-model="items.sales_discount_percentage"
                  class="form-control"
                  placeholder="0.00"
                  min="0"
                  step="any"
                  @keyup="discountAmount()"
                  @change="discountAmount()"
                  type="number"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  v-model="items.sales_discount_amount"
                  @keyup="discountPercentage()"
                  @change="discountPercentage()"
                  @keydown.tab.exact="
                    options.edit == true ? updateItem() : addItem()
                  "
                  class="form-control wd-20pimp"
                  placeholder="0.00"
                  min="0"
                  step="any"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 mr-0 pr-0">
              <label class="page-text" for="date">Total</label>
            </div>
            <div class="col-2 ml-0 pl-0">
              <input
                v-model="items.total"
                class="form-control"
                disabled
                type="number"
              />
            </div>
            <div class="col-9 d-flex justify-content-end">
              <div v-if="options.edit">
                <div
                  class="btn btn-success btn-xs"
                  type="button"
                  @click="updateItem"
                >
                  Update Item
                </div>
                <div
                  class="btn btn-danger btn-xs ml-2"
                  type="button"
                  @click="closeUpdate()"
                >
                  Close
                </div>
              </div>
              <div
                v-else
                class="btn btn-success btn-xs"
                type="button"
                @click="addItem"
              >
                Add Item
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th class="wd-5p table-start-item" scope>S.N.</th>
                <th class="wd-35p" scope>Particular</th>
                <th class="wd-10p" scope>Qty</th>
                <th class="wd-10p" scope> Available</th>
                <th class="wd-5p" scope>Rate</th>
                <th scope class="wd-10p">Disc.</th>
                <th class="wd-10p" scope>Sales Rate</th>
                <th scope class="wd-10p">Sales Disc.</th>
                <th class="wd-10p" scope>Total</th>
                <th class="wd-10p text-right table-end-item" scope>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th class="table-start-item" scope="row">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} {{ item.unit_type }}</td>
                <td>{{ item.available }} {{ item.unit_type }}</td>
                <td>{{ parseDigit(item.purchase_rate) }}</td>
                <td>
                  {{ parseDigit(item.purchase_discount_amount) }}
                </td>
                <td>{{ parseDigit(item.sales_rate) }}</td>
                <td>{{ parseDigit(item.sales_discount_amount) }}</td>
                <td>
                  {{ parseDigit(item.quantity * item.actual_purchase_rate) }}
                </td>
                <td class="text-right table-end-item">
                  <a class="mr-2" href="javascript:" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="mr-0" href="javascript:" @click="removeItem(index)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-3 pr-0">
            <div class="page-text">Note</div>
            <textarea
              v-model.trim="formData.note"
              class="form-control page-note"
              name="note"
            ></textarea>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    v-model="formData.total"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    step="any"
                    type="number"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">
                  VAT-able Amount
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="
                      Please adjust vatable amount only after adding the extra fields.If you add/edit extra field after editing the vatable amount,vatable amount will be reset and will be recalculated.
                    "
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    v-model="formData.vatable_amount"
                    class="form-control"
                    placeholder="0.00"
                    step="any"
                    type="number"
                    min="0"
                    @change="changeVatAbleAmount()"
                    @keyup="changeVatAbleAmount()"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">VAT Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.vat_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Paid Amount *</div>
                <div class="input-group">
                  <input
                    v-model="formData.paid_amount"
                    class="form-control"
                    placeholder="0.00"
                    min="0"
                    step="any"
                    type="number"
                    @change="changePaidAmount()"
                    @keyup="changePaidAmount()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                v-for="(extra_field, index) in extraFields"
                :key="index"
                class="col-2 mr-0 pr-0 mt-2"
              >
                <div
                  class="extra-cancel tx-danger"
                  title="Cancel"
                  @click="removeExtraField(index)"
                >
                  +
                </div>
                <div class="page-text">
                  {{ extra_field.title }} ({{
                    extra_field.type == 0 ? "+" : "-"
                  }})
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    :title="extra_field.vat == 1 ? 'Vatable' : 'Non Vatable'"
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    :id="`ext-${extra_field.slug}`"
                    :placeholder="extra_field.title"
                    class="form-control"
                    step="any"
                    type="number"
                    v-model="formData.reactiveExtraFields[index]"
                    @keyup="purchaseExtraFields({ ...extra_field })"
                    @change.prevent="purchaseExtraFields({ ...extra_field })"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0 mt-2"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">Bill Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.bill_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-3 mr-0 pr-0 mt-2">
                <div class="page-text">Due Amount</div>
                <div class="input-group">
                  <input
                    v-model="formData.due_amount"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-2 mr-0 pr-0 mt-3">
                <button
                  type="button"
                  v-b-modal.modal-multi-2
                  class="btn btn-xs btn-primary"
                  @click.prevent
                >
                  Extra Field
                </button>
                <!-- second modal -->
                <b-modal
                  id="modal-multi-2"
                  size="sm"
                  centered
                  title="Add New Field"
                  ok-only
                  hide-footer
                >
                  <AddNewExtraFieldModal />
                </b-modal>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-7 btn btn-success btn-sm btn-min-wd"
                type="button"
                @click.prevent="submitData()"
                :disabled="submitting || loading"
              >
                <span v-if="loading">Loading...</span>
                <span v-else>{{
                  submitting ? "Submitting...." : "Submit"
                }}</span>
              </button>

              <button
                v-if="checkSinglePermission('view_purchase')"
                class="col-7 btn btn-danger btn-sm btn-min-wd mt-2"
                type="button"
                @click.prevent="cancelPurchase()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="col-12">
      <div
        v-if="modalId == 'shippingDetils'"
        aria-hidden="true"
        class="modal fade modal_cust show"
        role="dialog"
        style="display: block"
        tabindex="-1"
      >
        <div
          class="
            modal-dialog modal-dialog-centered modal_ac_head
            single-column-popup
          "
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalCenterTitle" class="modal-title">
                Add Shipping Detial
              </h5>
              <a
                class="close"
                @click="$store.dispatch('modalClose', 'supplierList')"
                title="Close Modal"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div class="bg-theam-secondary">
              <div class="modal-body row">
                <div class="col-12 pd-r-7-5">
                  <div class="group">
                    <label class="group-label">Details</label>
                    <div class="group-attribute-container">
                      <div class="row mb-2">
                        <div class="col-5 mr-0 pr-0">Driver</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.driver"
                            class="form-control"
                            placeholder="Driver Name"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-5 mr-0 pr-0">Vehicle</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.vehicle"
                            class="form-control"
                            placeholder="vehicle number"
                            type="input"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5 mr-0 pr-0">Contact</div>
                        <div class="col-7 ml-0 pl-0">
                          <input
                            v-model="formData.contact"
                            class="form-control"
                            placeholder="9840000000"
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-right mt-3">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    @click="$store.dispatch('modalClose', 'shippingDetils')"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewItem />

    <PurchaseItemComponent
      @clicked="componentItemClicked"
      ref="purchaseItemComponent"
    ></PurchaseItemComponent>
    <PurchaseSlip @printSlip="printSlip" />
  </div>
</template>
<script>
/* eslint-disable */
import ExtraFieldModal from "./Components/ExtraFieldModal";
import AddNewExtraFieldModal from "./Components/AddNewExtraFieldModal";
import PurchaseSlip from "./PurchaseSlip";
import NewItem from "../Item/NewItem";
import PurchaseItemComponent from "./Components/PurchaseItemComponent";
import { mapGetters } from "vuex";
import Services from "./Services/Service";

export default {
  components: {
    NewItem,
    ExtraFieldModal,
    AddNewExtraFieldModal,
    PurchaseItemComponent,
    PurchaseSlip,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      dataSets: {
        suppliers: [],
        items: [],
        paymentMethods: [],
        banks: [],
        branches: [],
        purchase_setting: [],
        item_setting: [],
      },
      old_items:[],
      submitting: false,
      branchDisabled: false,
      supplierSearched: "",
      itemSearched: "",
      purchaseIdWithPrefix: 0,
      purchaseId: 0,
      currentMinItmQty: 0,
      loading: true,
      formData: {
        old_due_amount: 0,
        advance_amount: 0,
        image: "",
        bill_no: "",
        prefix: "",
        paymentTitle: "",
        reactiveExtraFields: [],
        branch: "",
        branchName: "",
        supplier_id: null,
        supplierName: "",
        date: null,
        chalan_no: null,
        file: null,
        shipping_type: 0,
        driver: "",
        vehicle: "",
        contact: "",
        payment_method: "",
        extra_fields: [],
        bank: null,
        txn_id: null,
        vatable_amount: null,
        vat_amount: null,
        purchase_amount: null,
        bill_amount: null,
        paid_amount: null,
        due_amount: null,
        total: null,
        note: null,
        items: [],
        old_items:[],
      },
      items: {
        item_id: "",
        name: null,
        unit_type: null,
        vat_applicable: null,
        availableQty: 0,
        quantity: 0,
        purchase_rate: null,
        actual_purchase_rate: 0,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        purchase_discount_percentage: null,
        purchase_discount_amount: null,
        total: 0,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists1",
      "dataLists8",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
    ...mapGetters("purchase", [
      "extraFields",
      "branchId",
      "selectedExtra",
      "slipSettings",
    ]),
    ...mapGetters("suppliers", ["supplierDatas"]),
    ...mapGetters("items", ["itemLists"]),
  },
  mounted() {
    this.$store.commit("purchase/removeExtraFields", "");
    this.getData();
  },
  methods: {
    searchSupplier: _.debounce(function () {
      let keyword = this.supplierSearched;
      // keyword = keyword ? keyword : '';
      Services.Purchase.getSuppliersBySearchKeyword(keyword)
        .then((response) => {
          let value = response.data.data.data;
          this.dataSets.suppliers = value;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600),

    openPurchaseitemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "purchaseItemList",
          mode: "create",
        });
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.purchaseItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e SalesItemComponent
    componentItemClicked(details) {
      // this.items = [];
      this.items.item_id = details.id;
      this.items.name = details.name;
      this.items.unit_type = details.unit_type.title;
      this.items.vat_applicable = details.vat_applicable;
      this.items.availableQty = 0;
      this.items.quantity = 1;
      // this.items.actual_purchase_rate = 0;
      this.items.barcode = details.barcode;
    },
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.quantity = 0;
      this.items.availableQty = 0;
      this.items.name = null;
      this.items.item_id = "";
      this.items.purchase_rate = null;
      this.items.sales_rate = null;
      this.items.sales_discount_percentage = null;
      this.items.sales_discount_amount = null;
      this.items.total = 0;
      this.items.barcode = null;
      this.items.expiry_date = null;
      this.items.purchase_discount_percentage = null;
      this.items.purchase_discount_amount = null;
      this.items.actual_purchase_rate = 0;
      this.items.unit_type = null;
    },
    base64Image(e) {
      this.formData.image = e.target.files[0]["name"];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.file = e.target.result;
      };
    },
    addItem() {
      if (this.itemValidation()) {
        if (this.formData.items.length > 0) {
          let id = this.items.item_id;
          let status = this.formData.items.find(function (data) {
            return data.item_id == id;
          });
          if (status != undefined) {
            let err = `${this.items.name} already in purchased list.`;
            this.setNotification(err);
            this.clearItem();
          } else {
            this.items.purchase_discount_percentage = parseFloat(
              this.items.purchase_discount_percentage || 0
            );

            this.items.purchase_discount_amount = parseFloat(
              this.items.purchase_discount_amount || 0
            );

            this.items.sales_discount_percentage = parseFloat(
              this.items.sales_discount_percentage || 0
            );

            this.items.sales_discount_amount = parseFloat(
              this.items.sales_discount_amount || 0
            );
            this.formData.items.push({ ...this.items });
            // to disable the branch selection
            this.branchDisabled = true;
            this.clearItem();
          }
        } else {
          this.items.purchase_discount_percentage = parseFloat(
            this.items.purchase_discount_percentage || 0
          );

          this.items.purchase_discount_amount = parseFloat(
            this.items.purchase_discount_amount || 0
          );

          this.items.sales_discount_percentage = parseFloat(
            this.items.sales_discount_percentage || 0
          );

          this.items.sales_discount_amount = parseFloat(
            this.items.sales_discount_amount || 0
          );
          this.formData.items.push({ ...this.items });
          this.branchDisabled = true;
          this.itemExpired = false;
          this.addFormDataToLocalStorage(this.formData);
          this.clearItem();
        }
        this.calculateAmount();
      }
    },
    itemValidation() {
      if (!this.items.item_id) {
        this.setNotification("Please Select Item first.");
        return false;
      }
      if (this.items.quantity <= 0) {
        this.setNotification("Quantity must be greater than 0.");
        return false;
      }
      if (
        !this.items.purchase_rate ||
        this.items.purchase_rate <= 0 ||
        typeof this.items.purchase_rate == "undefined"
      ) {
        this.setNotification("Purchase Rate cannot be less than 0.");
        return false;
      }
      if (this.items.purchase_discount_percentage < 0) {
        this.setNotification(
          "Purchase discount percentage cannot be less than 0."
        );
        return false;
      }
      if (this.items.purchase_discount_amount < 0) {
        this.setNotification("Purchase discount amount cannot be less than 0.");
        return false;
      }
      if (
        !this.items.sales_rate ||
        this.items.sales_rate < 0 ||
        typeof this.items.sales_rate == "undefined"
      ) {
        this.setNotification("Sales Rate cannot be less than 0.");
        return false;
      }
      if (this.items.total < 0 || typeof this.items.total == "undefined") {
        this.setNotification("Total cannot be less than 0.");
        return false;
      }
      if (this.items.sales_discount_percentage < 0) {
        this.setNotification(
          "Sales discount percentage cannot be less than 0."
        );
        return false;
      }
      if (this.items.sales_discount_amount < 0) {
        this.setNotification("Sales discount amount cannot be less than 0.");
        return false;
      }
      if (!this.items.purchase_id) this.items.purchase_id = null;
      return true;
    },
    formDataValidation() {
      if (this.formData.items.length < 0) {
        this.setNotification("Please add items first.");
        return false;
      }
      if (this.formData.paid_amount < 0) {
        this.setNotification("Paid amount cannot be less than 0.");
        return false;
      }
      return true;
    },
    // Notificaion
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    discountAmount() {
      if (
        isNaN(
          this.items.sales_discount_percentage ||
            this.items.sales_discount_percentage == 0
        )
      ) {
        this.items.sales_discount_amount = 0;
      }
      if (
        this.items.sales_discount_percentage > 0 &&
        this.items.sales_rate > 0
      ) {
        this.items.sales_discount_amount = this.parseDigit(
          (parseFloat(this.items.sales_rate) *
            parseFloat(this.items.sales_discount_percentage)) /
            100
        );
      }
    },
    discountPercentage() {
      if (
        isNaN(this.items.sales_discount_amount) ||
        this.items.sales_discount_amount == 0
      ) {
        this.items.sales_discount_percentage = 0;
      }
      if (this.items.sales_rate && this.items.sales_discount_amount > 0) {
        this.items.sales_discount_percentage = this.parseDigit(
          (parseFloat(this.items.sales_discount_amount) /
            parseFloat(this.items.sales_rate)) *
            100
        );
      }
    },
    removeItem(index) {
    //  this.items = { ...this.formData.items[index - 1] };
      let allow =true;
      let item_id = this.formData.items[index - 1].item_id;
      Object.values(this.old_items).filter(function(data){
            if(data.item_id == item_id){
              allow=false;
            }else{
              allow=true;
            }
      });
      if(allow=true){
          this.$swal({
            title: "Do you really want to remove this item ?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              this.formData.items.splice(index - 1, 1);
              this.addFormDataToLocalStorage(this.formData);
              this.calculateAmount();
            }
          });
      }else{
        if(this.formData.items[index - 1].quantity ==
        this.formData.items[index - 1].available){
          this.$swal({
            title: "Do you really want to remove this item ?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              this.formData.items.splice(index - 1, 1);
              this.addFormDataToLocalStorage(this.formData);
              this.calculateAmount();
            }
          });
        }else{
            this.setNotification("Sold Item cannot be removed.");
        }
      }
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      this.currentMinItmQty =
        this.formData.items[index - 1].quantity -
        this.formData.items[index - 1].available;
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      if (this.currentMinItmQty > this.items.quantity) {
        this.setNotification(
          this.currentMinItmQty +
            " " +
            this.items.unit_type +
            " of " +
            this.items.name +
            " is either sold or returend. Quantity cannot be lower than " +
            this.currentMinItmQty
        );
      } else {
        if (this.itemValidation()) {
          let dataAdded = false;
          let currentAddedItems = this.formData.items;
          currentAddedItems.forEach((el, index) => {
            if (el.item_id == this.items.item_id) {
              dataAdded = true;
              this.formData.items[index] = { ...this.items };
            }
          });
          if (dataAdded == false) {
            this.formData.items.push({ ...this.items });
          }
          this.options.edit = false;
          this.branchDisabled = true;
          this.calculateAmount();
          this.clearItem();
          this.currentMinItmQty = 0;
        }
      }
    },
    closeUpdate() {
      this.options.edit = false;
      // this.items = [];
      this.clearItem();
      this.itemExpired = false;
    },
    calculate() {
      this.items.total = this.parseDigit(
        this.items.quantity * this.items.actual_purchase_rate
      );
    },

    calculatePurchaseDiscount() {
      let rate = this.items.purchase_rate;
      let itemQuantity = this.items.quantity;
      let p_discountPerc = this.items.purchase_discount_percentage;
      if (rate > 0 && p_discountPerc > 0) {
        let discAmount = parseFloat(
          (p_discountPerc * (itemQuantity * rate)) / 100
        );
        this.items.purchase_discount_amount = this.parseDigit(discAmount);
      } else if (rate > 0 && p_discountPerc == 0) {
        this.items.purchase_discount_amount = 0;
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (p_discountPerc * rate) / 100
      );
      this.calculate();
    },
    changePurchaseRate() {
      let rate = parseFloat(this.items.purchase_rate || 0);
      let purchaseDiscountAmt = parseFloat(
        this.items.purchase_discount_amount || 0
      );

      if (purchaseDiscountAmt == 0 || isNaN(purchaseDiscountAmt)) {
        this.items.purchase_discount_percentage = 0;
        var purchaseDiscountPer = 0;
      } else {
        purchaseDiscountPer = this.parseDigit(
          (purchaseDiscountAmt / parseFloat(rate * this.items.quantity)) * 100
        );
        this.items.purchase_discount_percentage =
          this.parseDigit(purchaseDiscountPer);
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (purchaseDiscountPer * rate) / 100
      );
      this.calculate();
    },
    changeVatAbleAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.calculateCustomAmount();
    },
    calculateAmount() {
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? parseFloat(this.formData.paid_amount || 0)
          : 0;
      this.formData.items.forEach((item) => {
        item.total = this.parseDigit(
          item.quantity * parseFloat(item.purchase_rate || 0) -
            parseFloat(item.purchase_discount_amount || 0)
        );

        this.formData.total = this.parseDigit(
          parseFloat(this.formData.total || 0) + parseFloat(item.total || 0)
        );
        if (item.vat_applicable == 1) {
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total || 0)
          );
        }
      });
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.formData.bill_amount = this.parseDigit(
        parseFloat(this.formData.total || 0) +
          parseFloat(this.formData.vat_amount || 0)
      );
      // this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      this.formData.due_amount = this.parseDigit(
        parseFloat(this.formData.bill_amount || 0) -
          parseFloat(this.formData.paid_amount || 0)
      );
      this.formData.due_amount =
        this.formData.due_amount > 0 ? this.formData.due_amount : 0;

      this.calculateVatableAmtExtraFieldCase();
    },

    changePaidAmount() {
      if (!this.formData.paid_amount) {
        this.formData.due_amount = this.formData.bill_amount;
      }
      if (
        parseFloat(this.formData.paid_amount || 0) >
        parseFloat(this.formData.bill_amount || 0)
      ) {
        this.formData.paid_amount = this.formData.bill_amount;
      }
      if (
        parseFloat(this.formData.paid_amount || 0) <=
        parseFloat(this.formData.bill_amount || 0)
      ) {
        if (parseFloat(this.formData.paid_amount) > 0) {
          this.formData.due_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) -
              parseFloat(this.formData.paid_amount || 0)
          );
        } else {
          this.formData.due_amount = this.parseDigit(this.formData.bill_amount);
        }
      } else {
        this.formData.paid_amount == this.parseDigit(this.formData.bill_amount);
      }
      // this.calculateCustomAmount();
    },
    submitData() {
      if (this.formDataValidation()) {
        this.submitting = true;
        let id = this.$route.params.id;
        Services.PurchaseEdit.updatePurchase(this.formData, id)
          .then((res) => {
            if (
              res.status == 200 &&
              res.data.success_message == "Purchase modified successfully."
            ) {
              this.reset();
              //making array because purchaseSlip always accepts an array of data
              let purchaseArray = [];
              purchaseArray.push(res.data.data.purchase);
              this.$store.commit("purchase/setDataSets", purchaseArray);

              // providing the organization and purchase setting to purchaseSlip component
              this.$store.commit("purchase/setSlipSettings", res.data.data);
              //poping up the purchaseSlip modal
              this.$store.commit("setDataId", res.data.data.purchase.id);

              this.$store.dispatch("modalWithMode", {
                id: "purchaseSlip",
                mode: "read",
              });
            }
          })
          .catch((error) => {
            //validation error
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print();
    },
    selectSupplier(id) {
      let details = this.dataSets.suppliers.find(function (data) {
        return data.id == id;
      });
      this.formData.supplier_id = details.id;
      this.formData.supplierName = details.name;
    },
    selectPaymentMethod() {
      this.dataSets.paymentMethods.forEach((element) => {
        if (element.id == this.formData.payment_method) {
          if (element.title.toLowerCase() == "bank") {
            this.formData.paymentTitle = "bank";
            this.formData.bank =
              this.dataSets.banks.length > 0 ? this.dataSets.banks[0].id : "";
          } else {
            this.formData.bank = "";
            this.formData.paymentTitle = "";
          }
        }
      });
      //
      localStorage.setItem(
        "purchasePaymentMethod",
        this.formData.payment_method
      );
      // updating the localstorage fomrData;
      this.addFormDataToLocalStorage(this.formData);
    },
    selectBank() {
      this.addFormDataToLocalStorage(this.formData);
    },
    getData() {
      let id = this.$route.params.id;
      this.loading = true;
      Services.PurchaseEdit.getPurchaseDataById(id)
        .then((response) => {
          let value = response.data.data;
          this.dataSets.items = value.items;
          this.dataSets.branches = value.branches;
          this.dataSets.purchase_setting = value.purchase_setting;
          this.dataSets.item_setting = value.item_setting;
          this.purchaseIdWithPrefix = `${this.dataSets.purchase_setting.prefix}0`;
          this.formData.prefix = value.purchase.prefix;
          this.formData.branch = value.purchase.branch.id;
          this.formData.branchName = value.purchase.branch.name;
          this.branchDisabled = true;
          this.formData.supplierName = value.purchase.supplier.name;
          this.formData.supplier_id = value.purchase.supplier_id;
          this.formData.bill_no = value.purchase.purchase_no;
          this.purchaseIdWithPrefix =
            value.purchase.prefix + value.purchase.purchase_no;
          this.formData.chalan_no = value.purchase.chalan_no;
          this.formData.driver = value.purchase.driver;
          this.formData.contact = value.purchase.contact;
          this.formData.vehicle = value.purchase.vehicle;
          this.formData.date = value.purchase.date;
          this.formData.file = value.purchase.file;
          this.formData.shipping_type = value.purchase.shipping_type;
          this.formData.payment_method = value.purchase.payment_method;
          if (value.purchase.bank) {
            this.formData.bank = value.purchase.bank.id;
          }
          this.old_items = {...value.purchase.items};
          this.formData.txn_id = value.purchase.txn_id;
          this.formData.note = value.purchase.note;
          this.formData.items = value.purchase.items;
          this.formData.vatable_amount = value.purchase.vatable_amount;
          this.formData.vat_amount = value.purchase.vat_amount;
          this.formData.purchase_amount = value.purchase.purchase_amount;
          this.formData.advance_amount = value.purchase.paid_amount;
          this.formData.paid_amount = value.purchase.paid_amount;
          this.formData.bill_amount = value.purchase.bill_amount;
          this.formData.due_amount = value.purchase.due_amount;
          this.formData.old_due_amount = value.purchase.due_amount;
          this.formData.total = value.purchase.total;
          if (this.formData.bank) {
            this.formData.paymentTitle = "bank";
          }
          this.$store.commit("purchase/setItemToBePurchased", value.items);
          this.$store.commit("purchase/setItemGroups", value.item_groups);
          this.getDataByBranch();

          if (value.purchase.extra_fields.length > 0) {
            value.purchase.extra_fields.forEach((ef) => {
              this.$store.commit("purchase/setExtraFields", ef);
              this.formData.reactiveExtraFields.push(ef.amount);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data.error_message != "undefiend ") {
            if (error.response.data.error_message == "Access Denied.") {
              this.$router.push({ name: "access-denied" });
            }
          }
          if (typeof error.response.status == 500) {
            // let err = error.response.data.error_message;
            this.$router.push({ name: "purchase-listing" });
            setTimeout(() => {
              this.setNotification("Problem editing the purchase item.");
            }, 600);
          }
        });
    },
    getDataByBranch() {
      if (this.formData.branch) {
        Services.Purchase.getPurchaseItemsByBranch(this.formData.branch)
          .then((response) => {
            let value = response.data.data;
            this.$store.commit("purchase/setBranchId", this.formData.branch);
            this.dataSets.paymentMethods = value.paymentMethods;
            this.dataSets.banks = value.banks;
          })
          .catch((error) => {
            if (typeof error.response.data.error_message != "undefiend ") {
              if (error.response.data.error_message == "Access Denied.") {
                this.$router.push({ name: "access-denied" });
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.purchaseIdWithPrefix = 1;
      }
    },
    purchaseExtraFields(details) {
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields[index].amount = amount;
        } else {
          this.formData.extra_fields[index].amount = 0;
        }
      } else {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields.push({
            title: details.title,
            slug: details.slug,
            type: details.type,
            vat: details.vat,
            amount: amount,
          });
        }
      }
      this.calculateVatableAmtExtraFieldCase();
      // adding data to localStorage
      // this.addFormDataToLocalStorage(this.formData);
    },
    calculateVatableAmtExtraFieldCase() {
      this.newVatable = 0;

      this.formData.extra_fields.forEach((extra, index) => {
        if (index == 0) {
          this.newVatable = 0;
        }
        if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 1) {
          this.newVatable = this.newVatable - parseFloat(extra.amount || 0);
        } else if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 0) {
          this.newVatable = this.newVatable + parseFloat(extra.amount || 0);
        } else {
          this.newVatable = this.newVatable + 0;
        }
      });

      this.calculateTotal();
    },
    calculateTotal() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.due_amount = 0;
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? this.parseDigit(this.formData.paid_amount || 0)
          : 0;
      var itemVatable = false;
      this.formData.items.forEach((item) => {
        this.formData.total =
          this.parseDigit(this.formData.total) + parseFloat(item.total);
        if (this.formData.shipping_type == 0 && item.vat_applicable == 1) {
          itemVatable = true;
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total)
          );
        }
      });
      if (itemVatable) {
        this.formData.vatable_amount = this.parseDigit(
          parseFloat(this.formData.vatable_amount || 0) +
            parseFloat(this.newVatable || 0)
        );
      }
      this.calculateCustomAmount();
    },
    calculateCustomAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.formData.purchase_amount = parseFloat(this.formData.total || 0);
      this.formData.bill_amount = this.parseDigit(
        parseFloat(this.formData.total) +
          parseFloat(this.formData.vat_amount || 0)
      );
      this.formData.extra_fields.forEach((extra) => {
        if (parseInt(extra.type) == 0) {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) +
              parseFloat(extra.amount || 0)
          );
          this.formData.purchase_amount = this.parseDigit(
            parseFloat(this.formData.purchase_amount || 0) +
              parseFloat(extra.amount || 0)
          );
        } else {
          this.formData.bill_amount = this.parseDigit(
            parseFloat(this.formData.bill_amount || 0) -
              parseFloat(extra.amount || 0)
          );
          this.formData.purchase_amount = this.parseDigit(
            parseFloat(this.formData.purchase_amount || 0) -
              parseFloat(extra.amount || 0)
          );
        }
      });
      this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      this.calculateDue();
    },
    calculateDue() {
      if (
        parseFloat(this.formData.paid_amount) >
        this.parseDigit(this.formData.bill_amount)
      ) {
        this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      }
      this.formData.due_amount = this.parseDigit(
        this.parseDigit(this.formData.bill_amount) -
          parseFloat(this.formData.paid_amount)
      );
      this.formData.due_amount =
        this.formData.due_amount > 0
          ? this.parseDigit(this.formData.due_amount)
          : 0;
    },
    reset() {
      this.formData.chalan_no = null;
      this.formData.shipping_type = 0;
      this.formData.supplierName = "";
      (this.formData.supplier_id = null), (this.formData.driver = "");
      this.formData.vehicle = "";
      this.formData.contact = "";
      this.formData.paymentTitle = "";
      this.formData.reactiveExtraFields = [];
      this.formData.bank = null;
      this.formData.txn_id = null;
      this.formData.vatable_amount = null;
      this.formData.vat_amount = null;
      this.formData.bill_amount = null;
      this.formData.extra_fields = [];
      this.formData.paid_amount = null;
      this.formData.due_amount = null;
      this.formData.total = null;
      this.formData.items = [];
      // single and multiple branch case
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        this.purchaseId++;
        this.formData.bill_no = this.purchaseId;
        this.purchaseIdWithPrefix =
          this.formData.prefix + this.formData.bill_no;
        this.formData.payment_method = "";
      } else {
        this.branchDisabled = false;
        this.formData.bill_no = ++this.purchaseId;
      }
      this.$store.commit("purchase/removeExtraFields", "");
      this.clearItem();
    },
    addFormDataToLocalStorage(value) {
      localStorage.setItem("purchaseFormData", JSON.stringify(value));
      this.$store.commit("purchase/setBranchId", this.formData.branch);
      // this.calculateTotal();
    },
    cancelPurchase() {
      this.$router.push({ name: "purchase-listing" });
    },

    removeExtraField(index) {
      this.$swal({
        title: "Do you really want to remove this extra field ?",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove it!",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.extraFields.forEach((ex, i) => {
            if (i == index) {
              this.extraFields.splice(i, 1);
              this.formData.reactiveExtraFields.forEach((re, j) => {
                if (j == index) {
                  this.formData.reactiveExtraFields.splice(j, 1);
                }
                this.calculateVatableAmtExtraFieldCase();
              });
            }
          });
        }
      });
    },
  },
  watch: {
    extraFields(value) {
      this.formData.extra_fields = value;
    },
    eventMessage(value) {
      if (value.indexOf("Supplier created successfully.") >= 0) {
        this.dataSets.suppliers = this.supplierDatas;
      }
      if (value.indexOf("Item created successfully.") >= 0) {
        this.dataSets.items = this.itemLists;
        this.$store.commit("purchase/setItemToBePurchased", this.itemLists);
      }
    },
    "formData.shipping_type"(value) {
      if (value) {
        this.formData.vatable_amount = 0;
        this.formData.vat_amount = 0;
        this.calculateVatableAmtExtraFieldCase();
      } else {
        this.calculateTotal();
        this.calculateVatableAmtExtraFieldCase();
      }
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("items");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}

.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}

.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}

.input-group-text {
  outline: none;
}

input,
select,
textarea {
  background: #f4f7fa;
}

input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}

.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
